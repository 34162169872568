import React, {useEffect, useState } from "react";
import { Container, Row, Col, Card, Button} from "react-bootstrap";

import { useParams } from "react-router-dom";

import { getEquipmentByID } from "../utils/API";


const Tool = () => {
    const [tool, setTool] = useState({});
    const { id } = useParams();

    console.log(id);
    useEffect(() => {
        console.log("useEffect");
        const fetchTool = async () => {
            try {
                console.log("fetchTool");
                const response = await getEquipmentByID(id);
                setTool(response);
            }
            catch (error) {
                console.error('An error occurred while fetching the Equipment:', error);
            }
        }   
        fetchTool();
    }, []);

    console.log(tool);
    return (
        <>
        {tool && (
        <Container className="myContainer">
            <Container className="greenContainer">
            <Card>
                <Row>
                    <Col>
                        <Card.Title>{tool.equipmentName}</Card.Title>
                        <Card.Img variant="top" src={tool.pics && tool.pics.length > 0 ? tool.pics[0] : ''} />
                    </Col>
                    <Col>
                        <Card.Body>
                            <Card.Text>{tool.equipmentDescription}</Card.Text>
                            <Card.Text><strong>Price: </strong>${tool.price} per hour</Card.Text>
                            <Card.Text>Specifications</Card.Text>
                            <Card.Text>{tool.equipmentSpecs}</Card.Text>
                            <Card.Text>Brand: {tool.equipmentBrand}</Card.Text>
                            {/* <Card.Text>Found in: {tool.equipmentType.typeName}/{tool.equipmentSubType.subtypeName}</Card.Text> */}
                        </Card.Body>
                    </Col>
                </Row>
            </Card>
            <Button className="greeenButton">Rent</Button>
            
            </Container>
        </Container>
        )}
        </>
    );
    };

export default Tool;