import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import { getSubtypes } from "../utils/API";
import { getTypes } from "../utils/API";

const Equipment = () => {
    const [types, setTypes] = useState([]);
    const [subtypes, setSubtypes] = useState([]);
    const [selectedType, setSelectedType] = useState(null);

    const fetchSubtypes = async () => {
        try {
          const response = await getSubtypes();
          const result = await response;    
            setSubtypes(result.subtypes);
        }
        catch (error) {
          console.error('An error occurred while fetching the Subtypes:', error);
        }
    };

    const fetchTypes = async () => {
        try {
          const response = await getTypes();
          const result = await response;    
            setTypes(result.types);
        }
        catch (error) {
          console.error('An error occurred while fetching the Types:', error);
        }
    };

    const handleTypeClick = async (type) => {
        setSelectedType(selectedType === type._id ? null : type._id);
        console.log(selectedType);
        const response = await getSubtypes();
        console.log(response);
        // const data = await response;

        // Filter subtypes based on type ID and set them
        const filteredSubTypes = response.subtypes.filter(subtype => subtype.typeName._id === type._id);
        setSubtypes(filteredSubTypes);
    }

    useEffect(() => {
        fetchSubtypes();
        fetchTypes();
    }, []);

    return (

            <Row>
                <Col className="w-75">
                    <Container className="equipContainer">
                        {types.map((type) => (
                            <Card className="equipCard">
                                <Card.Body key={type._id}>
                                    <Card.Text onClick={() => handleTypeClick(type)}>{type.typeName} &#8594;
                                    </Card.Text>
                                    {selectedType === type._id && subtypes.length > 0 && subtypes.map((subType, index) => (
                                        <div key={index}>
                                            <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<Link to={`/equipmentgallery/${subType._id}`} style={{ color: 'inherit', textDecoration: 'inherit' }}>{subType.subtypeName} &#8594; </Link></p>
                                        </div>
                                    ))}
                                </Card.Body>
                            </Card>
                        ))}
                    </Container>
                </Col>
            </Row>
        
    );
};

export default Equipment;
