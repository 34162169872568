import React, {useState, useEffect} from "react";
import { Card, Table } from "react-bootstrap";
import { getTypes } from "../../utils/API";
import DeleteType from "./deleteType";
import AddType from "./addType";

const ListTypes = () => {
    const [types, setTypes] = useState([]);

    const fetchTypes = async () => {
      try {
        const response = await getTypes('/api/types');
        const result = await response;    
          setTypes(result.types);
      }
      catch (error) {
        console.error('An error occurred while fetching the Types:', error);
      }
    };

    const handleTypeUpdate = () => {
      fetchTypes();
    };

    useEffect(() => {
      fetchTypes();
    }, []);
  
    return (
      <>
        <AddType onTypeUpdate={handleTypeUpdate}/>
        {types.map((type, index) => (
          <Card key ={index} className="blankCard"> 
          <Card.Header as="h5">Type: {type.typeName}</Card.Header>
          <Card.Body>
            <Card.Text>
              {type.typeDescription}
            </Card.Text>
          </Card.Body>
          <Card.Footer>
            <DeleteType id={type.id} onTypeUpdate={handleTypeUpdate}/>
          </Card.Footer>
          </Card>      
        ))}
      </>
    );
  };
  
  export default ListTypes;