import React, { useEffect, useState } from "react";
import { Row, Col, Container, Card, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import Search from "../components/search";

import { getLocation } from "../utils/API";
import { latitude, longitude } from "../utils/location";
import getDistance from "../utils/getdistance";


const Home = () => {
    const [searchResults, setSearchResults] = useState([]);

    const addLocationToEquipment = async (results) => {
        const locationPromises = results.map(equipment =>
            getLocation(equipment.equipmentZipcode)
        );

        const locations = await Promise.all(locationPromises);

        return results.map((equipment, index) => ({
            ...equipment,
            equipmentLatitude: locations[index].LAT,
            equipmentLongitude: locations[index].LNG,
        }));
    };


    const handleSearchResults = async (results) => {
        const updatedResults = await addLocationToEquipment(results);
        const updatedDistanceResults = updatedResults.map((equipment) => ({
            ...equipment,
            distance: getDistance(
                latitude,
                longitude,
                equipment.equipmentLatitude,
                equipment.equipmentLongitude
            ),
        }));
        setSearchResults(updatedDistanceResults.sort((a, b) => a.distance - b.distance));
    };

    return (
        <>
        <Row>
            <Col></Col>
            <Col>
                <Container className="greenContainer">
                    <h1>Under Construction</h1>
                    <p>This site is currently under construction, please check back soon.  If you are interested in listing equipment for rent, please send us an email.</p>
                </Container>

                <Container className="greenContainer"> 
                    <Search onSearchResults={handleSearchResults} />
                </Container>
            </Col>
            <Col></Col>
        </Row>
        <Row>
            <Col>
            {searchResults.length > 0 && searchResults.map((result) => (
                <Container className="myContainer" key={result._id}>
                    <Container className="clearContainer">
                        <Card>
                            <Card.Body>
                                <Card.Title>{result.equipmentName}</Card.Title>
                                <Card.Text>Description:&nbsp;{result.equipmentDescription}</Card.Text>
                                <Card.Text>Price per hour: ${result.equipmentPrice}</Card.Text>
                                <Card.Text>Specs:&nbsp;{result.equipmentSpecs}</Card.Text>
                                <Card.Text>Category:&nbsp;{result.equipmentType.typeName}/{result.equipmentSubType.subtypeName}</Card.Text>
                                <Card.Footer>
                                    Distance: {result.distance} miles
                                </Card.Footer>
                                <Button className="greenButton" variant="primary"><Link to={`/tool/${result._id}`}>More Info</Link></Button>
                            </Card.Body>
                        </Card>
                    </Container>
                </Container>
            ))}
            </Col>
        </Row>
        </>     
    )
};

export default Home;