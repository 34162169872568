import React from "react";
import { Container } from "react-bootstrap";    
import { addProject } from "../../utils/API";

const AddProject = () => {
    const [project, setProject] = React.useState({});
    
    const handleChange = (e) => {
        setProject({ ...project, [e.target.name]: e.target.value });
    };
    
    const handleSubmit = (e) => {
        e.preventDefault();
        addProject(project).then((res) => {
        if (res.status === 200) {
            console.log("Project added successfully");
        } else {
            console.error("Failed to add project");
        }
        });
    };
    
    return (
        <Container className="greenContainer">
            <form onSubmit={handleSubmit}>
            <div className="form-group">
                <label htmlFor="name">Project Name</label>
                <input
                type="text"
                className="form-control"
                id="name"
                name="name"
                onChange={handleChange}
                />
            </div>
            <div className="form-group">
                <label htmlFor="description">Project Description</label>
                <input
                type="text"
                className="form-control"
                id="description"
                name="description"
                onChange={handleChange}
                />
            </div>
            <button type="submit" className="btn adminButton">
                Add Project
            </button>
            </form>
        </Container>
    );
    };

export default AddProject;
