import React, {useState, useEffect} from "react";
import { Card, Table } from "react-bootstrap";
import { getSubtypes } from "../../utils/API";
import DeleteSubtype from "./deleteSubtype";
import AddSubtype from "./addSubtype";  

const ListSubtypes = () => {
    const [subtypes, setSubtypes] = useState([]);

    const fetchSubtypes = async () => {
      try {
        const response = await getSubtypes('/api/subtypes');
          setSubtypes(response.subtypes);
      }
      catch (error) {
        console.error('An error occurred while fetching the Subtypes:', error);
      }
    };

    const handleSubtypeUpdate = () => {
      fetchSubtypes();
    };

    useEffect(() => {
      fetchSubtypes();
    }, []);
  
    return (
      <>
        <AddSubtype onSubtypeUpdate={handleSubtypeUpdate}/>
        {subtypes.map((subtype, index) => (
          <Card key={index} className="blankCard">
            <Card.Header as="h5">Subtype: {subtype.subtypeName}</Card.Header>
            <Card.Body>
              <Card.Text>
                {subtype.subtypeDescription}
              </Card.Text>
              <Card.Text>
                Type: {subtype.typeName.typeName}
              </Card.Text>
            </Card.Body>
            <Card.Footer>
              <DeleteSubtype id={subtype.id} onSubtypeUpdate={handleSubtypeUpdate}/>
            </Card.Footer>
          </Card>
        ))}
      </>
    );
  };
  
  export default ListSubtypes;