//   These need set to global so they can be exported and available throughout the app 
let latitude;
let longitude;

// Helper function to get coordinates.  These will be used to get distances for our site cards, and plot the lcoation on the map.
export function getLocation() {
    if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition(function(position) {
                latitude = position.coords.latitude;
                longitude = position.coords.longitude;
            },
            function(error) {
                if (error.code === error.PERMISSION_DENIED) {
                    alert(`We only want to use your location to ensure that you aren't wasting your day driving around the state to pick up equipment.  If you do not trust location services, please enter a zipcode in your profile.`)
                }
            }
        );
    } else {
        console.log("Geolocation is not supported by this browser.");
    }
};

export function checkLocationService() {
    return new Promise((resolve, reject) => {
      if ("geolocation" in navigator) {
        navigator.geolocation.getCurrentPosition(
          function(position) {
            resolve(true);
          },
          function(error) {
            if (error.code == error.PERMISSION_DENIED) {
              resolve(false);
            }
          }
        );
      } else {
        reject(new Error("Geolocation is not supported by this browser"));
      }
    });
  }

export {latitude, longitude};
