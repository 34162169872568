import React, { useEffect, useState } from "react";
import { Form, Button, Container, Row, Col, Alert, Card } from "react-bootstrap";
import { getUser, updateUser, getAllEquipment } from "../utils/API";
import Collapsible from "react-collapsible";

import Auth from "../utils/auth";   
import { checkLocationService } from "../utils/location";
import UserAgreementModal from "../components/signup-login/userAggreementModal";
import NewTool from "../components/equipment/newTool";


const Profile = () => {
    const user = Auth.getProfile();
    const [userID, setUserID] = useState(user.data._id);
    const [locationService, setLocationService] = useState(true);
    const [formState, setFormState] = useState({});
    const [showAlert, setShowAlert] = useState(false);
    const [userInfo, setUserInfo] = useState({});   
    const [show, setShow] = useState(false);
    const [toolShow, setToolShow] = useState(false);
    const [equipment, setEquipment] = useState([]);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleToolClose = () => {setToolShow(false); getEquipment();}

    const newToolShow = () => setToolShow(true);

    const getEquipment = async () => {
        try {
            const response = await getAllEquipment();
            const result = response.equipment.filter(equipment => equipment.equipmentProvider === userID);
            setEquipment(result);
        }
        catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        checkLocationService().then(isAllowed => {
            if (!isAllowed) {
                setLocationService(false);
            } else {
                setLocationService(true);
            }
        }).catch(err => {
            console.error(err);
            setLocationService(false);
        });
    }, []);
    
    useEffect(() => {
        const getUserInfo = async () => {
            try {
                const response = await getUser(userID);
                setUserInfo(response.data);
            }
            catch (err) {
                console.error(err);
            }
        }
        getUserInfo();
        getEquipment();
    }, []);
        


    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormState({
            ...formState,
            [name]: value,
        });

    };

    const handleFormSubmit = async (event) => {
        event.preventDefault();
        const updatedFormState = Object.fromEntries(
            Object.entries(formState).filter(([key, value]) => value || value === 0)
          );
        try {
            const response = await updateUser(userID, updatedFormState);
            if (!(response.status >= 200 && response.status < 300)) {
                throw new Error('something went wrong!');
            }
        } catch (err) {
            console.error(err);
            setShowAlert(true);
        }
    };

    return (
        <>
        <div>
            <h1>{user.data.username}'s Profile</h1>
        </div>

        <Col>
            <Container className="myContainer">
                <Container className="clearContainer">
                    <Row>
                        <Col xs={4} md={4}>
                            <p>Username:</p>
                            <p>Email:</p>
                            <p>First Name:</p>
                            <p>Last Name: </p>
                            {!locationService && <p>Zipcode: </p>}
                            <p>Phone: </p>
                            <p>Star Rating:</p>
                        </Col>
                        <Col xs={8} md={8}>
                            <p>{user.data.username}&nbsp;</p>
                            <p>{user.data.email}&nbsp;</p>
                            <p>{userInfo?.firstName}&nbsp;</p>
                            <p>{userInfo?.lastName}&nbsp;</p>
                            {!locationService && <p>{userInfo?.zipcode}&nbsp;</p>}
                            <p>{userInfo?.phone}&nbsp;</p>
                            <p>{userInfo?.star_rating}&nbsp;</p>
                        </Col>
                    </Row>
                </Container>
            </Container>
            <Container className="myContainer">
                <Container className="clearContainer">
                    <Row md={6}>
                        <h3>Reviews:</h3>
                    </Row>
                </Container>
            </Container>
            <Container className="myContainer">
                <Container className="clearContainer">
                    <Row md={6}>
                        <Form onSubmit={handleFormSubmit}>
                            {/* show alert if server response is bad */}
                            <Alert dismissible onClose={() => setShowAlert(false)} show={showAlert} variant='danger'>
                                Something went wrong with your signup!
                            </Alert>
                            {!locationService &&
                            <Form.Group className="mb-3" controlId="formZipcode">
                                <Form.Label>You disabled location services. Please enter your zipcode:</Form.Label>
                                <Form.Control type="zipcode" placeholder="Enter zipcode" onChange={handleInputChange} name="zipcode" />
                            </Form.Group>
                            }
                            <Collapsible className="h5" trigger="Change Profile Information &darr;">
                                <Form.Group className="mb-3" controlId="formProfile">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control type="email" placeholder="Enter new email" onChange={handleInputChange} name="email" />
                                    <Form.Label>First Name</Form.Label>
                                    <Form.Control type="text" placeholder="Enter new first name" onChange={handleInputChange} name="firstName" />
                                    <Form.Label>Last Name</Form.Label>
                                    <Form.Control type="text" placeholder="Enter new last name" onChange={handleInputChange} name="lastName" />
                                    <Form.Label>Phone</Form.Label>
                                    <Form.Control type="phone" placeholder="Enter new phone" onChange={handleInputChange} name="phone"/>
                                </Form.Group>
                            
                            <Button variant="primary" type="submit" className="greyButton">
                                Submit
                            </Button>
                            </Collapsible>
                        </Form>
                    </Row>
                </Container>
            </Container>
            {!userInfo.provider &&
            <Container className="myContainer">
                <Container className="clearContainer">
                    <Row md={6}>
                        <p>Are you interested in renting your equipment to others?  Click the button below to start listing your equipment.</p>
                    </Row>
                <Button className="greyButton" onClick={handleShow}>Start</Button>
                <UserAgreementModal userID={userID} show={show} handleClose={handleClose} />
                </Container>
            </Container>
            }
            {userInfo.provider &&
            <>
            <Container className="myContainer">
                <Container className="clearContainer">
                    <Row md={6}>
                        <p>Click the button below to start listing your equipment.</p>
                </Row>
                <Button className="greyButton" onClick={newToolShow}>Start</Button>
                    <NewTool userID={userID} toolShow={toolShow} handleToolClose={handleToolClose} />
                </Container>
            </Container>
            <Container className="myContainer">
                <Container className="clearContainer">
                    <Row md={6}>
                        <h3>Your Equipment:</h3>
                    </Row>
                    {equipment.length > 0 && equipment.map((item) => (
                        <>
                        <Card key={item.id}>
                            <Card.Body>
                                <Card.Title>{item.equipmentName}
                                </Card.Title>
                                <Card.Text>
                                    {item.equipmentDescription}
                                </Card.Text>
                                {/* Add more fields as needed */}
                            </Card.Body>
                        </Card>
                        </>
                    ))}
                </Container>
            </Container>
            </>
            }
        </Col>
        </>
    );
}

export default Profile;

