import React from "react";

const Cart = () => {
    return (
        <div>
        <h1>Cart</h1>
        </div>
    );
};

export default Cart;
