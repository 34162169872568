import { Form, Button, Alert, Card, Container } from 'react-bootstrap';
import { useState, useEffect } from "react";

import { getAllEquipment } from "../utils/API";

const Search = (props) => {
    const [search, setSearch] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [showAlert, setShowAlert] = useState(false);

    const handleFormSubmit = async (event) => {
        event.preventDefault();
        try {
            const response = await getAllEquipment();
            const data = response.equipment.filter(equipment => equipment.equipmentName.toLowerCase().includes(search.search.toLowerCase()));
            setSearchResults(data);
        } catch (err) {
            console.error(err);
            setShowAlert(true);
        }
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setSearch({ ...search, [name]: value });
    };

    useEffect(() => {
        props.onSearchResults(searchResults);
    }, [searchResults]);

    return (
        <>
            <Form onSubmit={handleFormSubmit}>
                <Alert dismissible onClose={() => setShowAlert(false)} show={showAlert} variant='danger'>
                    Something went wrong
                </Alert>
                <Form.Group>
                    <Form.Label>Search for equipment</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Search"
                        name="search"
                        onChange={handleInputChange}
                        required
                    />
                    <Form.Control.Feedback type="invalid">
                        Search is required!
                    </Form.Control.Feedback>
                </Form.Group>
                <Button className="greyButton" type="submit">Search</Button>
            </Form>

        </>
    );
}

export default Search;