import { Button } from "react-bootstrap";
import React from "react";
import { deleteType } from "../../utils/API";

const DeleteType = (props) => {
    const handleDelete = (e) => {
        e.preventDefault();
        deleteType(props.id).then((res) => {
            if (res.status === 200) {
                console.log("Type deleted successfully");
                props.onTypeUpdate();
            } else {
                console.error("Failed to delete type");
            }
        });
        console.log("Delete Type", props.id);
    };

    return (
        <Button variant="danger" onClick={handleDelete}>
            Delete
        </Button>
    );
};

export default DeleteType;
