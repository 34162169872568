import { Button } from "react-bootstrap";
import React from "react";
import { deleteSubtype } from "../../utils/API";

const DeleteSubtype = (props) => {
    const handleDelete = (e) => {
        e.preventDefault();
        deleteSubtype(props.id).then((res) => {
            if (res.status === 200) {
                console.log("Subtype deleted successfully");
                props.onSubtypeUpdate();
            } else {
                console.error("Failed to delete subtype");
            }
        });
        console.log("Delete Subtype", props.id);
    };

    return (
        <Button variant="danger" onClick={handleDelete}>
            Delete
        </Button>
    );
};

export default DeleteSubtype;