import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card} from "react-bootstrap";
import { useParams } from "react-router-dom";
import { getAllEquipment, getSubtypes } from "../utils/API";

const EquipmentGallery = () => {
    const { id } = useParams();

    const [type, setType] = useState('');
    const [subtype, setSubtype] = useState('');
    const [equipment, setEquipment] = useState([]);

    const fetchSubtypes = async () => {
        try {
            const response = await getSubtypes();
            const result = response.subtypes.filter(subtype => subtype._id === id);
            setSubtype(result[0].subtypeName);
            setType(result[0].typeName.typeName);
        }
        catch (error) {
            console.error('An error occurred while fetching the Subtypes:', error);
        }
    }

    const fetchEquipment = async () => {
        try {
            const response = await getAllEquipment();
            const result = response.equipment.filter(equipment => equipment.equipmentSubType.id === id);
            console.log(result);
            setEquipment(result);
            console.log(equipment);
            
        }
        catch (error) {
            console.error('An error occurred while fetching the Equipment:', error);
        }
    }

    useEffect(() => {
        fetchSubtypes();
        // fetchTypes();
        fetchEquipment();
    }, []);

    console.log(subtype, type);
    return (
        <Container>
            <Row>
                <Col>
                {equipment.length > 0 ? (
                    equipment.map((item) => (
                        <Card className="greenContainer" key={item.id}>
                            <Card.Body>
                                <Card.Title>{item.equipmentName}</Card.Title>
                                <Card.Text>
                                    {item.equipmentDescription}
                                </Card.Text>
                                <Card.Footer>
                                    {item.equipmentPrice}
                                </Card.Footer>
                            </Card.Body>
                        </Card>
                    ))
                ) : (
                    <Card>
                        <Card.Body>
                            <Card.Title>No Equipment Available</Card.Title>
                            <Card.Text>
                                No equipment of this type available
                            </Card.Text>
                        </Card.Body>
                    </Card>
                )}
                </Col>
            </Row>
        </Container>
    );
}

export default EquipmentGallery;