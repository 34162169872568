import { useState } from 'react';
import { Form, Button, Alert, Container, Col } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { loginUser } from '../utils/API';
import Auth from '../utils/auth';
import { useNavigate } from 'react-router-dom';

const Login = () => {
  const [userFormData, setUserFormData] = useState({ email: '', password: '' });
  const [validated] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const navigate = useNavigate(); 
  
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setUserFormData({ ...userFormData, [name]: value });
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    // check if form has everything (as per react-bootstrap docs)
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    try {
      const response = await loginUser(userFormData);
      if (!(response.status >= 200 && response.status < 300)) {
        throw new Error('something went wrong!');
      }

      const { token, user } = response.data;
      Auth.login(token);
      navigate('/');
    } catch (err) {
      console.error(err);
      setShowAlert(true);
    }

    setUserFormData({
      username: '',
      email: '',
      password: '',
    });
  };

  return (
    <>
      <Col>
        <Container className='myContainer'>
          <Container className='clearContainer'>
            <Form noValidate validated={validated} onSubmit={handleFormSubmit}>
              <Alert dismissible onClose={() => setShowAlert(false)} show={showAlert} variant='danger'>
                Something went wrong with your login credentials!
              </Alert>
              <Form.Group className='mb-3'>
                <Form.Label htmlFor='email'>Email</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Your email'
                  name='email'
                  onChange={handleInputChange}
                  value={userFormData.email}
                  required
                />
                <Form.Control.Feedback type='invalid'>Email is required!</Form.Control.Feedback>
              </Form.Group>

              <Form.Group className='mb-3'>
                <Form.Label htmlFor='password'>Password</Form.Label>
                <Form.Control
                  type='password'
                  placeholder='Your password'
                  name='password'
                  onChange={handleInputChange}
                  value={userFormData.password}
                  required
                />
                <Form.Control.Feedback type='invalid'>Password is required!</Form.Control.Feedback>
              </Form.Group>
              <Button
                disabled={!(userFormData.email && userFormData.password)}
                type='submit'
                variant='success'>
                Submit
              </Button>
            </Form>
          </Container>  
        </Container>
        <Container className='myContainer'>
          <Container className='clearContainer'>
            <Link as={Link} to='/signup'>Don't have an account?  </Link>
          </Container>
        </Container>
      </Col>
    </>
  );
};

export default Login;
