import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import ListProjects from "../components/admin/listProjects";
import ListTypes from "../components/admin/listTypes";
import AddProject from "../components/admin/addProject";
import Collapsible from "react-collapsible";

import ListSubTypes from "../components/admin/listSubtypes";
import AddSubtype from "../components/admin/addSubtype";



const admin= () => {
    return (
        <>
        <Container>
            <Row>
                <Col>
                    <Collapsible trigger="Edit User">

                    </Collapsible>
                </Col>
            </Row>
        </Container>
        <Container>
            <Row>
                <Col>
                    <Collapsible trigger="Edit Reviews">
                        <ListTypes />
                    </Collapsible>
                </Col>
            </Row>
        </Container>
        <Container>
            <Row>
                <Col>
                    <Collapsible trigger="Edit Types">
                        <ListTypes />
                    </Collapsible>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Collapsible trigger="Edit Subtypes">
                        <ListSubTypes />
                        {/* <AddSubtype /> */}
                    </Collapsible>
                </Col>
            </Row>
        </Container>
        <Container>
            <Row>
                <Col>
                    <Collapsible trigger="Edit Projects">
                        <AddProject />
                        <ListProjects />
                    </Collapsible>
                </Col>
            </Row>
        </Container>
        </>
    )
}
export default admin