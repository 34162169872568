import React, { useState } from "react";
import SignupForm from "../components/signup-login/signupForm";


const Signup = () => {

    const [member, setMember] = useState(null);


    // get the member data from the ValidateMember component
    const handleMemberData = (data) => {
      setMember(data);
    };

    return (
      <>

        {/* Displays the Signup Form */}
        <SignupForm member={member} />
      </>
    );
  };
  
  export default Signup;