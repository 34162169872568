import { Link, useNavigate } from 'react-router-dom';
import { Navbar, Nav, Container } from 'react-bootstrap';
import logo from '../../assets/logo.jpg';
import Auth from '../../utils/auth';

const NavUI = () => {
  const navigate = useNavigate();

  const logout = (e) => {
    e.preventDefault();
    navigate('/');
    Auth.logout();

  }

  const closeMenu = () => {
    document.querySelector('.navbar-toggler').click();
  };

  return (
    <>
      <Navbar className="navbar " expand="lg">
        <Container> 
          <Navbar.Brand as={Link} to='/'>
            <img
              src={logo}
              width='100'
              height='45'
              className='d-inline-block align-top'
              alt='All Trades Equipment'
            />{' '}
          </Navbar.Brand>
            <Navbar.Toggle aria-controls='navbar' className='whiteButton'/>
            <Navbar.Collapse id='navbar'>
              <Nav>
                <Nav.Link onClick={closeMenu} className='nav-link' as={Link} to='/equipment'>Equipment</Nav.Link>
                {Auth.loggedIn() ? (
                  <>
                    {Auth.isAdmin() && <Nav.Link onClick={closeMenu} className='nav-link' as={Link} to='/admin'>Admin </Nav.Link>}
                    <Nav.Link onClick={closeMenu} className='nav-link' as={Link} to='/profile'>Profile </Nav.Link>
                    <Link className='nav-link' to="/" onClick={logout}>Logout</Link>
                  </>
                ) : (
                  <Nav.Link onClick={closeMenu} className='nav-link' as={Link} to='/login'>Login/Sign Up</Nav.Link>
                )}
              </Nav>
            </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default NavUI;