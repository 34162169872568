import React from "react";
import { Modal, Button, Container } from "react-bootstrap";
import { updateUser } from "../../utils/API";

function UserAgreementModal({ userID, show, handleClose}) {
    const handleAgree = async (event) => {
        event.preventDefault();
        try {

            const response = await updateUser(userID, {"provider": true});
            if (!(response.status >= 200 && response.status < 300)) {
                throw new Error('something went wrong!');
            }
        } catch (err) {
            console.error(err);
        };
        handleClose();
    };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
        All Trades Equipment User Agreement
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="agreement-outline">
        <h4>Terms and Conditions</h4>
        <Container className="scrollable-paragraph">
            <p className="agreement-text">
                Welcome to All Trades Equipment, a platform for renting equipment. This User Agreement ("Agreement") outlines the terms and conditions governing your use of our website and services. By accessing or using our website, you agree to abide by this Agreement. Please read it carefully.
            </p>
            <p className="agreement-text">
                Acceptance of Terms: By accessing or using our website, you acknowledge that you have read, understood, and agree to be bound by this Agreement and any additional terms and conditions referenced herein.
            </p>
            <p className="agreement-text">
                User Eligibility: You must be at least 18 years old and capable of entering into a legally binding agreement to use our services. By using our website, you represent and warrant that you meet these eligibility requirements.
            </p>
            <p className="agreement-text">
                Equipment Listings: We provide a platform for users to list and rent equipment. While we strive to ensure the accuracy of listings, we do not guarantee the availability, condition, or suitability of any equipment listed on our website. Users are solely responsible for verifying the details of equipment before renting.
            </p>
            <p className="agreement-text">
                Rental Agreement: When you rent equipment through our platform, you enter into a rental agreement directly with the equipment owner. We are not a party to this agreement and are not responsible for any disputes arising from rental transactions.
            </p>
            <p className="agreement-text">
                User Conduct: You agree to use our website in compliance with all applicable laws and regulations. You further agree not to:  Violate the rights of others, including intellectual property rights.   
            </p>

            <p className="agreement-text">
                Engage in fraudulent or deceptive activities.
                Harass, intimidate, or harm other users.
                Interfere with the operation of our website or disrupt the services we provide.
            </p>
            <p className="agreement-text">
                Fees and Payments: Users may be required to pay rental fees, security deposits, and other charges as specified by equipment owners. We will collect payment on behalf of equipment owners and facilitate transactions through our platform. By renting equipment, you agree to pay all applicable fees and charges.
            </p>
            <p className="agreement-text">
                Liability: We are not responsible for any damages, losses, or injuries arising from the use of rented equipment. Users assume all risks associated with renting and using equipment listed on our website.
            </p>
            <p className="agreement-text">
                Intellectual Property: All content and materials on our website, including text, graphics, logos, and software, are the property of [Website Name] or its licensors and are protected by intellectual property laws. You may not use, reproduce, or distribute any content from our website without our prior written consent.
            </p>
            <p className="agreement-text">
                Privacy: Our Privacy Policy governs the collection, use, and disclosure of personal information provided by users. By using our website, you consent to the terms of our Privacy Policy.
		    </p>
            <p className="agreement-text">
                Termination: We reserve the right to suspend or terminate your access to our website and services at any time, without prior notice, for any reason or no reason, including if we believe you have violated this Agreement.
            </p>
            <p className="agreement-text">
                Changes to Terms: We may revise this Agreement from time to time by posting the updated terms on our website. Your continued use of our website after any such changes constitutes acceptance of the revised Agreement.
            </p>
            <p className="agreement-text">
                Governing Law: This Agreement shall be governed by and construed in accordance with the laws of your state, without regard to its conflict of laws principles.
            </p>
            <p className="agreement-text">
                Contact Us: If you have any questions or concerns about this Agreement, please contact us at ((all trades email)).
                By using our website, you acknowledge that you have read, understood, and agree to be bound by this Agreement.
            </p>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleAgree}>I Agree</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default UserAgreementModal;