import React from "react";
import { Container } from "react-bootstrap";    
import { addType } from "../../utils/API";

const AddType = (props) => {
    const [type, setType] = React.useState({});
    
    const handleChange = (e) => {
        setType({ ...type, [e.target.name]: e.target.value });
    };
    
    const handleSubmit = (e) => {
        e.preventDefault();
        addType(type).then((res) => {
            console.log(type);
        if (res.status === 200) {
            console.log("Type added successfully");
            props.onTypeUpdate();
        } else {
            console.error("Failed to add Type");
        }
        });
    };
    
    return (
        <Container className="greenContainer">
            <form onSubmit={handleSubmit}>
            <div className="form-group">
                <label htmlFor="name">Type Name</label>
                <input
                type="text"
                className="form-control"
                id="typeName"
                name="typeName"
                onChange={handleChange}
                />
            </div>
            <div className="form-group">
                <label htmlFor="description">Type Description</label>
                <input
                type="text"
                className="form-control"
                id="typeDescription"
                name="typeDescription"
                onChange={handleChange}
                />
            </div>
            <button type="submit" className="btn adminButton">
                Add Type
            </button>
            </form>
        </Container>
    );
    };

export default AddType;
