import React, { useState, useEffect }from "react";
import { Modal, Button, Container, Form} from "react-bootstrap";
import Select from "react-select";
import { getSubtypes, getTypes, createEquipment } from "../../utils/API";

function NewTool({userID, toolShow, handleToolClose}) {
    const [types, setTypes] = useState([]);
    const [subtypes, setSubtypes] = useState([]);   
    const [formState, setFormState] = useState({});
    const [showAlert, setShowAlert] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);
    const [selectedSubtypeOption, setSelectedSubtypeOption] = useState(null);
    const [isTypeSelected, setIsTypeSelected] = useState(false);

    const options = types.map(type => ({ value: type._id, label: type.typeName }));
    const subtypeOptions = subtypes.map(subtype => ({ value: subtype._id, label: subtype.subtypeName }));

    useEffect(() => {
        const getTypesData = async () => {
            try {
                const response = await getTypes();
                setTypes(response.types);
            }
            catch (err) {
                console.error(err);
            }
        }
        getTypesData();
    }, []);

    useEffect(() => {
        if (selectedOption) {
            getSubtypesData();
        }
    }, [selectedOption]);

        const getSubtypesData = async () => {
            try {
                const response = await getSubtypes();
                console.log(selectedOption.equipmentType);
                const result = response.subtypes.filter(subtype => subtype.typeName._id === selectedOption.equipmentType);
                console.log(result);
                setSubtypes(result);
            }
            catch (err) {
                console.error(err);
            }
        }

    const handleInputChange = (event) => {
        const { name, value, files } = event.target;
        if (name === 'pics') {
            setFormState({ ...formState, [name]: Array.from(files) });
        } else {
            setFormState({
                ...formState,
                [name]: value,
            });
        }  
        console.log(formState); 
    }

    const handleTypeChange = (option) => {
        setSelectedOption({
            equipmentType: option.value,
        });
        getSubtypesData();
        setIsTypeSelected(true);
    };

    const handleSubtypeChange = (option) => {
        setSelectedSubtypeOption({
            equipmentSubType: option.value,
        });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
    
        const formData = new FormData();
        Object.keys(formState).forEach(key => {
            if (key === 'pics') {
                formState[key].forEach((file) => {
                    formData.append(key, file);
                });
            } else {
                formData.append(key, formState[key]);
            }
        });
        formData.append('equipmentType', selectedOption.equipmentType);
        formData.append('equipmentSubType', selectedSubtypeOption.equipmentSubType);
        formData.append('equipmentProvider', userID);
    
        try {
            const response = await createEquipment(formData);
            if (!(response.status >= 200 && response.status < 300)) {
                throw new Error('something went wrong');
            }
            const data = await response.data;
            setShowAlert(false);
            handleToolClose();
        }
        catch (err) {
            console.error(err);
            setShowAlert(true);
        }
    };

    return (
        <Modal show={toolShow} onHide={handleToolClose}>
        <Modal.Header closeButton>
            <Modal.Title>Add New Tool</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Form onSubmit={handleSubmit}> 
                <Form.Group>
                    <Form.Label>Tool Name</Form.Label>
                    <Form.Control name="equipmentName" onChange={handleInputChange} type="text" placeholder="Enter tool name" />
                </Form.Group>

                <Form.Group>
                    <Form.Label>Tool Description</Form.Label>
                    <Form.Control name="equipmentDescription" onChange={handleInputChange} type="text" placeholder="Enter tool description" />
                </Form.Group>
                <Form.Group>
                    <Form.Label>Tool Specifications</Form.Label>
                    <Form.Control name="equipmentSpecs" onChange={handleInputChange} type="text" placeholder="Enter tool specifications" />
                </Form.Group>
                <Form.Group>
                    <Form.Label>Tool Price in $ per hour</Form.Label>
                    <Form.Control name="equipmentPrice" onChange={handleInputChange} type="text" placeholder="Enter tool price in dollars per hour" />
                </Form.Group>
                <Form.Group>
                    <Form.Label>Tool Brand Name</Form.Label>
                    <Form.Control name="equipmentBrand" onChange={handleInputChange} type="text" placeholder="Enter tool brand" />
                </Form.Group>
                <Form.Group>
                    <Form.Label>Zipcode</Form.Label>
                    <Form.Control name="equipmentZipcode" onChange={handleInputChange} type="number" placeholder="Zipcode where the tool is located." />
                </Form.Group>
                <Form.Group>
                    <Form.Label>Tool Images</Form.Label>
                    <Form.Control name="pics" accept='image/*' multiple onChange={handleInputChange} type="file" />
                </Form.Group>
                <Form.Group>
                    <Form.Label>Tool Type</Form.Label>
                    <Select options={options} onChange={handleTypeChange} styles={{ menu: (provided) => ({ ...provided, width: '100%' }) }} />
                </Form.Group>
                <Form.Group>
                    <Form.Label>Tool Subtype</Form.Label>
                    <Select options={subtypeOptions} onChange={handleSubtypeChange} isDisabled={!isTypeSelected} styles={{ menu: (provided) => ({ ...provided, width: '100%' }) }} />
                </Form.Group>
                
                <Button variant="primary" type="submit">
                    Submit
                </Button>
            </Form>
        </Modal.Body>
        </Modal>
    )
};

export default NewTool;