// import our css files here
import './App.css';


// package imports
// import React, { createContext } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

//utils imports
import { SiteProvider } from './utils/SiteContext';
import { getLocation } from './utils/location';

//component imports
import NavUI from './components/navbar/Navbar';

//page imports
import Home from './pages/Home';
import Login from './pages/Login';
import Signup from './pages/Signup';
import Admin from './pages/Admin';
import Cart from './pages/Cart';
import Tool from './pages/Tool';
import Profile from './pages/Profile';
import NotFound from './pages/NotFound';
import Equipment from './pages/Equipment';
import EquipmentGallery from './pages/EquipmentGallery';

function App() {
  getLocation();

  return (
    <SiteProvider>
      <Router>
        <NavUI/>
          <Routes>
            <Route exact path='/' element={<Home />} />
            <Route exact path='/equipment' element={<Equipment />} />
            <Route exact path='/login' element={<Login />} />
            <Route exact path='/signup' element={<Signup />} />
            <Route exact path='/admin' element={<Admin />} />
            <Route exact path='/tool/:id' element={<Tool />} />
            <Route exact path='/cart' element={<Cart />} />
            <Route exact path='/profile' element={<Profile />} />
            <Route exact path='/equipmentgallery/:id' element={<EquipmentGallery />} />
            <Route path='*' element={<NotFound />}/>
          </Routes>
      </Router>
    </SiteProvider>
  );
}

export default App;
