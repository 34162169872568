import React, {useState, useEffect} from "react";
import { Container } from "react-bootstrap";    
import { addSubtype } from "../../utils/API";
import { Dropdown } from "react-bootstrap";
import { getTypes } from "../../utils/API";

const AddSubtype = (props) => {
    const [subtype, setSubtype] = useState({ type: "65cac8dae98a53e09d64dc48"});
    const [types, setTypes] = useState([]);

    const fetchTypes = async () => {
        try {
          const response = await getTypes('/api/types');
          const result = await response;    
            setTypes(result.types);
        }
        catch (error) {
          console.error('An error occurred while fetching the Types:', error);
        }
      };

    const handleChange = (e) => {
        console.log(e.target.name, e.target.value); 
        setSubtype({ ...subtype, [e.target.name]: e.target.value });
    };
    
    const handleSubmit = (e) => {
        e.preventDefault();
        console.log("Sending subtype: ", subtype);
        addSubtype(
            {
                subtypeName: subtype.subtypeName,
                subtypeDescription: subtype.subtypeDescription,
                typeName: subtype.type
            }
        ).then((res) => {
            console.log(res);
        if (res.status === 200) {
            console.log("Subtype added successfully");
            props.onSubtypeUpdate();
        } else {
            console.error("Failed to add subtype");
        }
        });
    };

    useEffect(() => {
        fetchTypes();
    }, []);
    
    return (
        <Container className="greenContainer">
            <form onSubmit={handleSubmit}>
            <div className="form-group">
                <label htmlFor="name">Subtype Name</label>
                <input
                    type="text"
                    className="form-control"
                    id="name"
                    name="subtypeName"
                    onChange={handleChange}
                />
            </div>
            <div className="form-group">
                <label htmlFor="description">Subtype Description</label>
                <input
                    type="text"
                    className="form-control"
                    id="description"
                    name="subtypeDescription"
                    onChange={handleChange}
                />
            </div>
            <div className="form-group">
                <label htmlFor="type">Type</label>
                <select id="type" name="type" className="form-control" onChange={handleChange}>
                    {types.map((type, index) => (
                        <option key={index} value={type._id}>
                            {type.typeName}
                        </option>
                    ))}
                </select>
            </div>
            <button type="submit" className="btn adminButton">
                Add Subtype
            </button>
            </form>
        </Container>
    );
    };

export default AddSubtype;
