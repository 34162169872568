import axios from 'axios';

// const API_URL = 'http://localhost:3003';
const API_URL = 'https://alltradesequipmentsales.com';

//Location related API calls  
export const getLocation = async (zipcode) => {
  try {
    const response = await axios.get(`${API_URL}/api/location/${zipcode}`);
    return response.data;
  } catch (error) {
    console.error('An error occurred while fetching the Locations:', error);
  }
};

//Subtype related API calls
export const getSubtypes = async () => {
  try {
    const response = await axios.get(`${API_URL}/api/subtypes`);
    return response.data;
  } catch (error) {
    console.error('An error occurred while fetching the Subtypes:', error);
  }
};

export const addSubtype = async (subtypeData) => {
  try {
    const response = await axios.post(`${API_URL}/api/subtypes`, subtypeData);
    return response;
  } catch (error) {
    console.error('An error occurred while adding a Subtype:', error);
  }
};

export const deleteSubtype = async (id) => {
  try {
    const response = await axios.delete(`${API_URL}/api/subtypes/${id}`);
    return response.data;
  } catch (error) {
    console.error('An error occurred while deleting a Subtype:', error);
  }
};

//Type related API calls
export const getTypes = async () => {
  try {
    const response = await axios.get(`${API_URL}/api/types`);
    return response.data;
  } catch (error) {
    console.error('An error occurred while fetching the Types:', error);
  }
};

export const addType = async (typeData) => {
  try {
    const response = await axios.post(`${API_URL}/api/types`, typeData);
    return response.data;
  } catch (error) {
    console.error('An error occurred while adding a Type:', error);
  }
};

export const deleteType = async (id) => {
  try {
    const response = await axios.delete(`${API_URL}/api/types/${id}`);
    return response.data;
  } catch (error) {
    console.error('An error occurred while deleting a Type:', error);
  }
};

//Project related API calls
export const getProjects = async () => {
  try {
    const response = await axios.get(`${API_URL}/api/projects`);
    return response.data;
  } catch (error) {
    console.error('An error occurred while fetching the Projects:', error);
  }
};

export const addProject = async (projectData) => {
  try {
    const response = await axios.post(`${API_URL}/api/projects`, projectData);
    return response.data;
  } catch (error) {
    console.error('An error occurred while adding a Project:', error);
  }
};

//Calendar related API calls
export const getCalendar = async () => {
  try {
    const response = await axios.get(`${API_URL}/api/calendar`);
    return response.data;
  } catch (error) {
    console.error('An error occurred while fetching the Calendar:', error);
  }
};

export const createCalendar = async (calendarData) => {
  try {
    const response = await axios.post(`${API_URL}/api/calendar`, calendarData);
    return response.data;
  } catch (error) {
    console.error('An error occurred while creating a Calendar:', error);
  }
};

export const deleteCalendar = async (id) => {
  try {
    const response = await axios.delete(`${API_URL}/api/calendar/${id}`);
    return response.data;
  } catch (error) {
    console.error('An error occurred while deleting a Calendar:', error);
  }
};

//User related API calls
export const getUser = async (userId) => {
  try {
    const response = await axios.get(`${API_URL}/api/users/${userId}`);
    return response;
  } catch (error) {
    console.error('An error occurred while fetching the User:', error);
  }
};

export const updateUser = async (userId, userData) => {
  try {
    const response = await axios.put(`${API_URL}/api/users/${userId}`, userData);
    return response;
  } catch (error) {
    console.error('An error occurred while updating the User:', error);
  }
};

export const createUser = async (userData) => {
  try {
    const response = await axios.post(`${API_URL}/api/users`, userData);
    return response;
  } catch (error) {
    console.error('An error occurred while creating the User:', error);
  }
};

export const loginUser = async (userData) => {
  try {
    const response = await axios.post(`${API_URL}/api/users/login`, userData);
    return response;
  } catch (error) {
    console.error('An error occurred while logging in the User:', error);
  }
};

export const deleteUser = async (id) => {
  try {
    const response = await axios.delete(`${API_URL}/api/users/${id}`);
    return response;
  } catch (error) {
    console.error('An error occurred while deleting the User:', error);
  }
};

// Equipment related API calls
export const getAllEquipment = async () => {
  try {
    const response = await axios.get(`${API_URL}/api/equipment`);
    return response.data;
  } catch (error) {
    console.error('An error occurred while fetching the Equipment:', error);
  }
};

export const createEquipment = async (equipmentData) => {
  try {
    console.log("Client input, equipmentData: ", equipmentData);
    const response = await axios.post(`${API_URL}/api/equipment`, equipmentData, {
      headers: {'Content-Type': 'multipart/form-data'}
    });
    console.log("Client output, response: ", response);
    return response;
  } catch (error) {
    console.error('An error occurred while creating the Equipment:', error);
  }
};

export const updateEquipment = async (equipmentData) => {
  try {
    const response = await axios.put(`${API_URL}/api/equipment/${equipmentData.id}`, equipmentData);
    return response.data;
  } catch (error) {
    console.error('An error occurred while updating the Equipment:', error);
  }
};

export const deleteEquipment = async (id) => {
  try {
    const response = await axios.delete(`${API_URL}/api/equipment/${id}`);
    return response.data;
  } catch (error) {
    console.error('An error occurred while deleting the Equipment:', error);
  }
};

export const getEquipmentByID = async (id) => {
  try {
    const response = await axios.get(`${API_URL}/api/tool/${id}`);
    return response.data;
  } catch (error) {
    console.error('An error occurred while fetching the Equipment:', error);
  }
};




// User related API calls
// export const getMemberId = (memberId) => {
//   return fetch(`/api/members/${memberId}`, {
//     method: 'GET',
//     headers: {
//       'Content-Type': 'application/json',
//     },
//   });
// };

// export const getUser = (userId) => {
//   return fetch(`/api/users/${userId}`, {
//     method: 'GET',
//     headers: {
//       'Content-Type': 'application/json',
//     },
//   });
// };

// export const updateUser = (userId, userData) => {
//   return fetch(`/api/users/${userId}`, {
//     method: 'PUT',
//     headers: {
//       'Content-Type': 'application/json',
//     },
//     body: JSON.stringify(userData),
//   });
// };

// export const getMembers = () => {
//   return fetch('/api/members', {
//     method: 'GET',
//     headers: {
//       'Content-Type': 'application/json',
//     },
//   });
// };

// export const getProjects = () => {
//   return fetch('/api/projects', {
//     method: 'GET',
//     headers: {
//       'Content-Type': 'application/json',
//     },
//   });
// };

// export const addProject = (projectData) => {
//   return fetch('/api/projects', {
//     method: 'POST',
//     headers: {
//       'Content-Type': 'application/json',
//     },
//     body: JSON.stringify(projectData),
//   });
// };

// export const deleteProject = (id) => {
//   return fetch(`/api/projects/${id}`, {
//     method: 'DELETE',
//     headers: {
//       'Content-Type': 'application/json',
//     },
//   });
// };

// export const getTypes = () => {
//   return fetch('/api/types', {
//     method: 'GET',
//     headers: {
//       'Content-Type': 'application/json',
//     },
//   });
// };

// export const addType = (typeData) => {
//   return fetch('/api/types', {
//     method: 'POST',
//     headers: {
//       'Content-Type': 'application/json',
//     },
//     body: JSON.stringify(typeData),
//   });
// };

// export const deleteType = (id) => {
//   return fetch(`/api/types/${id}`, {
//     method: 'DELETE',
//     headers: {
//       'Content-Type': 'application/json',
//     },
//   });
// };

// export const getSubtypes = () => {
//   return fetch('/api/subtypes', {
//     method: 'GET',
//     headers: {
//       'Content-Type': 'application/json',
//     }
//   });
// };

// export const addSubtype = (subtypeData) => {
//   return fetch('/api/subtypes', {
//     method: 'POST',
//     headers: {
//       'Content-Type': 'application/json',
//     },
//     body: JSON.stringify(subtypeData),
//   });
// };

// export const deleteSubtype = (id) => {
//   return fetch(`/api/subtypes/${id}`, {
//     method: 'DELETE',
//     headers: {
//       'Content-Type': 'application/json',
//     },
//   });
// };

// export const createUser = (userData) => {
//   return fetch('/api/users', {
//     method: 'POST',
//     headers: {
//       'Content-Type': 'application/json',
//     },
//     body: JSON.stringify(userData),
//   });
// };

// export const loginUser = (userData) => {
//   return fetch('/api/users/login', {
//     method: 'POST',
//     headers: {
//       'Content-Type': 'application/json',
//     },
//     body: JSON.stringify(userData),
//   });
// };

// // Menu related API calls
// export const getTodaysMenu = () => {
//   return fetch('/api/menu/today', {
//     method: 'GET',
//     headers: {
//       'Content-Type': 'application/json',
//     },
//   });
// };

// export const deleteSpecial = (id) => {
//   return fetch(`/api/menu/${id}`, {
//     method: 'DELETE',
//     headers: {
//       'Content-Type': 'application/json',
//     },
//   });
// };

// export const createSpecial = (specialData) => {
//   return fetch('/api/menu', {
//     method: 'POST',
//     headers: {
//       'Content-Type': 'application/json',
//     },
//     body: JSON.stringify(specialData),
//   });
// };

// export const getSpecials = () => {
//   return fetch('/api/menu', {
//     method: 'GET',
//     headers: {
//       'Content-Type': 'application/json',
//     },
//   });
// };

// // News related API calls
// export const getNews = () => {
//   return fetch('/api/news', {
//     method: 'GET',
//     headers: {
//       'Content-Type': 'application/json',
//     },
//   });
// };

// export const createNews = (newsData) => {
//   return fetch('/api/news', {
//     method: 'POST',
//     headers: {
//       'Content-Type': 'application/json',
//     },
//     body: JSON.stringify(newsData),
//   });
// };

// export const updateNews = (newsData) => { 
//   console.log(newsData);
//   return fetch(`/api/news/${newsData.id}`, {
//     method: 'PUT',
//     headers: {
//       'Content-Type': 'application/json',
//     },
//     body: JSON.stringify(newsData),
//   });
// };

// export const deleteNews = (id) => {
//   return fetch(`/api/news/${id}`, {
//     method: 'DELETE',
//     headers: {
//       'Content-Type': 'application/json',
//     },
//   });
// };

// // Calendar related API calls
// export const getCalendar = () => {
//   return fetch('/api/calendar', {
//     method: 'GET',
//     headers: {
//       'Content-Type': 'application/json',
//     },
//   });
// };

// export const createCalendar = (calendarData) => {
//   return fetch('/api/calendar', {
//     method: 'POST',
//     headers: {
//       'Content-Type': 'application/json',
//     },
//     body: JSON.stringify(calendarData),
//   });
// };

// export const deleteCalendar = (id) => {
//   return fetch(`/api/calendar/${id}`, {
//     method: 'DELETE',
//     headers: {
//       'Content-Type': 'application/json',
//     },
//   });
// };

// // Gallery related API calls
// export const getGallery = () => {
//   return fetch('/api/gallery', {
//     method: 'GET',
//     headers: {
//       'Content-Type': 'application/json',
//     },
//   });
// };

// export const createGallery = (galleryData) => {
//   console.log(...galleryData);
//   return fetch('/api/gallery', {
//     method: 'POST',
//     headers: {
//       'Content-Type': 'application/json',
//     },
//     body: galleryData,
//   });
// };

// export const updateGallery = (galleryData) => {
//   return fetch(`/api/gallery/${galleryData._id}`, {
//     method: 'PUT',
//     headers: {
//       'Content-Type': 'application/json',
//     },
//     body: JSON.stringify(galleryData),
//   });
// };

// export const deleteGallery = (id) => {
//   return fetch(`/api/gallery/${id}`, {
//     method: 'DELETE',
//     headers: {
//       'Content-Type': 'application/json',
//     },
//   });
// };

// export const getGalleryItem = (id) => {
//   return fetch(`/api/gallery/${id}`, {
//     method: 'GET',
//     headers: {
//       'Content-Type': 'application/json',
//     },
//   });
// };

// export const addReaction = (galleryId, galleryData) => {
//   console.log(galleryId, galleryData);
//   return fetch(`/api/gallery/${galleryId}/reaction`, {
//     method: 'POST',
//     headers: {
//       'Content-Type': 'application/json',
//     },
//     body: JSON.stringify(galleryData),
//   });
// };

// export const deleteReaction = (galleryId, reactionId) => {
//   return fetch(`/api/gallery/${galleryId}/reaction/${reactionId}`, {
//     method: 'DELETE',
//     headers: {
//       'Content-Type': 'application/json',
//     },
//   });
// }


// // TV related API calls
// export const getTV = () => { 
//   return fetch('/api/tv', {
//     method: 'GET',
//     headers: {
//       'Content-Type': 'application/json',
//     },
//   });
// };
