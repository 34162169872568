import React, {useState, useEffect} from "react";
import { Table } from "react-bootstrap";
import { getProjects } from "../../utils/API";

const ListProjects = () => {
    const [projects, setProjects] = useState([]);

    useEffect(() => {
       const fetchProjects = async () => {
        try {
        const response = await getProjects('/api/projects');
        const result = await response;
        setProjects(result.projects);
         } catch (error) {
            console.error('An error occurred while fetching the Projects:', error);
            }
        };
            fetchProjects();
        }, []);

  
    return (
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Project ID</th>
            <th>Project Name</th>
            <th>Project Description</th>
          </tr>
        </thead>
        <tbody>
          {projects.map((project) => (
            <tr key={project.id}>
              <td>{project.id}</td>
              <td>{project.projectName}</td>
              <td>{project.projectDescription}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    );
  };
  
  export default ListProjects;